<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="اضافة جديد"
          icon="pi pi-plus"
          v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('productsAdd')"
          @click="$router.push('products/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash"
          v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('productsDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        />
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :filters="filters"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه المنتجات والخدمات
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />
      <Column
        field="code"
        header="الكود"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['code']"
            class="p-column-filter"
            placeholder="بحث بالكود"
          />
        </template>
      </Column>
      <Column
        field="name"
        header="الاسم"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['name']"
            class="p-column-filter"
            placeholder="بحث بالاسم"
          />
        </template>
      </Column>

      <!-- <Column
        field="users"
        header="الموظفين"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Chip
            v-for="item in slotProps.data.users"
            :key="item.id"
            :label="item.name"
          />
        </template>
      </Column> -->

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('products/edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            v-tooltip="'تعديل'"
            v-if="$checkRoles('productsEdit')"
            class="p-button-success p-ml-2  p-button-rounded"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            v-tooltip="'حذف'"
            v-if="$checkRoles('productsDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded"
          ></Button>
        </template>
      </Column>
      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="slotProps.data.items"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              <Button
                label="اضافة خدمه جديده"
                icon="pi pi-plus"
                v-tooltip="'اضافه جديد'"
                class="p-ml-2 p-button-success"
                v-if="$checkRoles('itemsAdd')"
                @click="$router.push('items/add/' + slotProps.data.id)"
              />
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="id" header="الكود">
              <template #body="slotProps2">
                {{ slotProps2.index + 1 }}
              </template>
            </Column>

            <Column field="name" header="الاسم" />

            <Column
              field="users"
              header="الموظفين"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <Chip
                  v-for="item in slotProps2.data.users"
                  :key="item.id"
                  :label="item.name"
                />
              </template>
            </Column>
            <Column
              field="id"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  v-tooltip="'تعديل'"
                  v-if="$checkRoles('itemsEdit')"
                  class="p-button-success p-ml-2  p-button-rounded"
                  @click="$router.push(`items/edit/${slotProps2.data.id}`)"
                />

                <Button
                  type="button"
                  icon="pi pi-trash"
                  v-tooltip="'حذف'"
                  v-if="$checkRoles('itemsDelete')"
                  @click="deleteSubItem(slotProps2.data.id)"
                  class="p-button-warning  p-button-rounded"
                ></Button>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      expandedRows: [],
      loading: true,
      filters: {},
    };
  },
  methods: {
    getData() {
      this.$http
        .post(`products/search`, {
          relations: ['items'],
        })
        .then(
          (response) => {
            this.loading = false;
            this.list = response.data;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .delete(`products/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`products/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
    deleteSubItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`items/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    if (!this.$checkRoles('productsVeiw')) {
      this.$router.push('/admin/');
    }
    this.getData();
  },
};
</script>
